import React from "react";
import ReactDOM from "react-dom/client";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel, DEFAULT_SECTIONS } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import App1 from "./App1";
import { loadFile } from "./file";
import { QrSection } from "./qr-section";
import { QuotesSection } from "./quotes-section";
import { IconsSection } from "./icons-section";

import { ShapesSection } from "./shapes-section";
import { StableDiffusionSection } from "./stable-diffusion-section";
import { TemplatesSection } from "./templates-panel";
import Topbar from "./topbar";

const sections = [TemplatesSection, ...DEFAULT_SECTIONS];
const root = ReactDOM.createRoot(document.getElementById("root"));
// DEFAULT_SECTIONS.splice(3, 0, IllustrationsSection);
// replace elements section with just shapes
sections.push(ShapesSection);
// DEFAULT_SECTIONS.splice(2, 0, StableDiffusionSection);
// add icons

sections.splice(3, 0, IconsSection);
// add two more sections
sections.push(QuotesSection, QrSection);

sections.push(StableDiffusionSection);

const useHeight = () => {
	const [height, setHeight] = React.useState(window.innerHeight);
	React.useEffect(() => {
		window.addEventListener("resize", () => {
			setHeight(window.innerHeight);
		});
	}, []);
	return height;
};

const App = ({ store }) => {
	const check = sessionStorage.getItem("trans");

	const [checked, setChecked] = React.useState(false);

	const handleChange = () => {
		const textdata = sessionStorage.getItem("textdata");
		console.log(textdata);
		root.render(
			<React.StrictMode>
				<App1 text1={textdata} />
			</React.StrictMode>
		);
	};

	const handleDrop = (ev) => {
		// Prevent default behavior (Prevent file from being opened)
		ev.preventDefault();

		// skip the case if we dropped DOM element from side panel
		// in that case Safari will have more data in "items"
		if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
			return;
		}
		// Use DataTransfer interface to access the file(s)
		for (let i = 0; i < ev.dataTransfer.files.length; i++) {
			loadFile(ev.dataTransfer.files[i], store);
		}
	};

	const height = useHeight();

	return (
		<div
			style={{
				width: "100vw",
				height: height + "px",
				display: "flex",
				flexDirection: "column",
			}}
			onDrop={handleDrop}
		>
			<Topbar store={store} />

			<div style={{ height: "calc(100% - 50px)" }}>
				<PolotnoContainer className="polotno-app-container">
					<SidePanelWrap>
						<SidePanel
							store={store}
							sections={sections}
							defaultSection={"custom-templates"}
						/>
					</SidePanelWrap>
					<WorkspaceWrap>
						<Toolbar store={store} />
						<div
							style={{
								padding: "5px",
								overFlow: "hidden",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<label style={{ fontSize: "20px" }}>
								{" "}
								Transliteration
							</label>
							<input
								style={{
									position: "absolute",
									width: "20px",
									marginLeft: "13%",
									height: "20px",
									display: "flex",
									flexDirection: "column",
								}}
								type="checkbox"
								value={checked}
								checked={checked}
								onClick={handleChange}
							/>
						</div>
						<Workspace store={store} />

						<ZoomButtons store={store} />
					</WorkspaceWrap>
				</PolotnoContainer>
			</div>
		</div>
	);
};

export default App;
