import React from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { InputGroup } from '@blueprintjs/core';
import { SectionTab } from 'polotno/side-panel';
import MdPhotoLibrary from '@meronex/icons/md/MdPhotoLibrary';

import { ImagesGrid } from 'polotno/side-panel/images-grid';
import Paidpopup from './Paidpopup';

export const TemplatesPanel = observer(({ store }) => {
  // load data
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [subscriptionDetailsList, setSubscriptionDetailsList] = React.useState(null)
  const { data, isLoading, loadMore, setQuery } = useInfiniteAPI({
    getAPI: ({ page ,query }) => `https:/gamesbox.in/templates/getTemplate.php?query=${query}`,
  });

  React.useEffect(() => {
    getTemplateList()
  }, [])

  

  const getTemplateList = async() => {
    try {
      const paidStatus = await fetch(`https://gamesbox.in/designfistapi/test.php?gettemplates`)
      const paidStatusRes = await paidStatus.text()
      const newArr = paidStatusRes.split("}").map((item, index) => {
        if(index + 1 !== paidStatusRes.split("}").length)
          return JSON.parse(item.concat("}"))
      })
      newArr.pop()

      setSubscriptionDetailsList(newArr)
    } catch (error) {
      console.error(error)
    }
  }

  const checkSubscriptionValidation = async (imageIdentifier) => {
    try {
      if(subscriptionDetailsList && subscriptionDetailsList.length > 0){
        subscriptionDetailsList.find(item => item.path === imageIdentifier).paid === "yes" && setShowPopUp(true)
        console.log(data.items);
    }else{

    	const req = await fetch(`https://gamesbox.in/templates/${imageIdentifier.json}`);
        const json = await req.json();
        store.loadJSON(json);
    }
    } catch (error) {
      console.error(error)
    }
  }

  const callForPremiumIcons = () => {
    console.log(subscriptionDetailsList)
    let imageNodesList = [];
    //Primium icon node design
    const node = document.createElement("img");
    node.setAttribute('src', './logo.png')
    node.style.width = '20px';
    node.style.height = '20px';
    node.style.position = 'absolute';
    node.style.top = '5px';
    node.style.left = '5px';

    document.getElementById('templatePanel').lastChild.childNodes.forEach(templateItem => {
      templateItem.childNodes.forEach(childItem => {
        childItem.childNodes.forEach((superChildItem, index) => {
          imageNodesList.push(superChildItem)
        })
      })
    })
    console.log({
      imageNodesList: imageNodesList
    })
    if(imageNodesList && imageNodesList.length > 0) {
      imageNodesList.forEach((item, index) => {
        console.log({
          subscriptionDetailsList: subscriptionDetailsList[index]
        })
        if(subscriptionDetailsList[index].paid === "yes"){
          const childImageElement = document.querySelector(`img[src$='${subscriptionDetailsList[index].img}']`)
          console.log({
            childImageElement: childImageElement
          })
          childImageElement.parentElement.setAttribute('data-id', index+1)
          childImageElement.parentNode.appendChild(node);
        }
      })
    }
  }

  return (
    <div id='templatePanel' style={{ height: '100%' ,display: 'flex', flexDirection: 'column' }}>
    <InputGroup
        leftIcon="search"
        placeholder="Search..."
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        style={{
          marginBottom: '20px',
        }}
      />
      
      <ImagesGrid
        shadowEnabled={false}
        images={data?.map((data) => data.items).flat()}
        getPreview={(item) => `https://gamesbox.in/templates/${item.preview}`}
        isLoading={isLoading}
        onSelect={async (item) => {
          // download selected json
          
          checkSubscriptionValidation(item.json)
          // just inject it into store
          
        }}
        rowsNumber={2}
      />
      {showPopUp && <Paidpopup setClosePopup={setShowPopUp} />}
    </div>
  );
});

// define the new custom section
export const TemplatesSection = {
  name: 'custom-templates',
  Tab: (props) => (
    <SectionTab name="Custom templates" {...props}>
      <MdPhotoLibrary />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: TemplatesPanel,
};
