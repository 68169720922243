import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { unstable_registerNextDomDrop } from "polotno/config";
import localforage from "localforage";
import axios from "axios";
import { createStore } from "polotno/model/store";
import { unstable_setRemoveBackgroundEnabled } from "polotno/config";
import { unstable_registerShapeModel } from "polotno/config";
import "./index.css";
import App from "./App";
import App1 from "./App1";
import Login from "./login";
import FontApi from "./FontApi";

unstable_setRemoveBackgroundEnabled(true);

const store = createStore({ key: "nFA5H9elEytDyPyvKL7T" });
window.store = store;

const check = sessionStorage.getItem("trans") === "false";
console.log(check);

console.log("working");

fetch("https://editor.gamesbox.in/fontsjson/getfonts.php")
	.then((res) => res.json())
	.then((json) => {
		for (var i = 0; i < json.length; i++) {
			console.log(json[i].name);
			store.addFont({
				fontFamily: json[i].name,
				url: json[i].url,
			});
		}
	});

localforage.getItem("polotno-state", function (err, json) {
	if (json) {
		axios
			.get("https://gamesbox.in/designfistapi/test.php?getjson")
			.then((res) => {
				console.log(res.data);

				store.loadJSON(res.data);
			})

			.catch((error) => {
				console.log(error);
			});

		//for fonts

		//console.log("Sessiontext "+newtext);

		//console.log("jsontext "+json.pages[0].children[0].text);
	}

	if (!store.pages.length) {
		store.addPage();
	}
});

let timeout = null;
const requestSave = () => {
	// if save is already requested - do nothing
	if (timeout) {
		return;
	}
	// schedule saving to the backend
	timeout = setTimeout(() => {
		// reset timeout
		timeout = null;
		// export the design
		const json = store.toJSON();
		// save it to the backend
		const postdata = { data: JSON.stringify(json) };
		console.log(postdata);
		axios
			.post(
				"https://gamesbox.in/designfistapi/test.php?updatedata",
				postdata
			)
			.then((res) => {
				console.log(res.data.message);

				axios
					.get("https://gamesbox.in/designfistapi/test.php?getdata")
					.then((res) => {
						console.log(res.data.message);
					})
					.catch((error) => {
						console.log(error);
					});
			})

			.catch((error) => {
				console.log(error);
			});
	}, 1000);
};

store.on("change", () => {
	requestSave();

	try {
		const json = store.toJSON();
		const length = store.pages.length;
		const element1 = store.pages[1];
		const element = store.selectedElements[0];
		const text = element.text;
		const id = element.id;
		const type = element.type;
		const pageid = store.activePage.id;
		console.log(text + " " + id + " " + pageid);
		sessionStorage.setItem("textdata", text);
		sessionStorage.setItem("textid", id);
		sessionStorage.setItem("pageid", pageid);
		localforage.setItem("polotno-state", json);
	} catch (e) {}
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const login = sessionStorage.getItem("loginsession");
console.log(login);
if (login == null) {
	root.render(
		<React.StrictMode>
			<Login />
		</React.StrictMode>
	);
} else {
	root.render(
		<React.StrictMode>
			<App store={store} />
		</React.StrictMode>
	);
}
